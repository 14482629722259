export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Courses",
    // path: '/catalog',
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
