export const FooterLink2 = [
  {
    title: "Subjects",
    links: [
      
      { title: "Mobile Development", link: "#" },
      { title: "Web Design", link: "#" },
      { title: "Web Development", link: "#" },
    ],
  },
  {
    title: "Languages",
    links: [

      { title: "C++", link: "#" },
 

      { title: "HTML & CSS", link: "#" },

      { title: "JavaScript", link: "#" },
   
    ],
  },
  {
    title: "Career building",
    links: [
        {title: "Career paths", link: "#"},
        {title: "Career services", link: "#"},
        {title: "Interview prep", link: "#"},
        {title: "Professional certification", link: "#"},
     
      
    ]
  }
];
